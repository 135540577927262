import range from 'lodash/range';

import * as dataHelpers from '~/domain/helpers';
import {
  FlowType,
  HubbleFlow,
  HubbleLink,
  HubbleService,
  IPProtocol,
  Verdict,
  TrafficDirection,
  AuthType,
} from '~/domain/hubble';

export const selectedNamespace = 'jobs-demo';

export const links: HubbleLink[] = [
  {
    id: 'reserved:world:outgoing',
    sourceId: 'reserved:world:outgoing',
    destinationId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationPort: 443,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: 'reserved:world:incoming:8080',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: 'reserved:world:incoming',
    destinationPort: 8080,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: 'reserved:world:incoming:8080',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: 'reserved:world:incoming',
    destinationPort: 8080,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Dropped,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: 'reserved:world:incoming:443',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: 'reserved:world:incoming',
    destinationPort: 443,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Dropped,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: 'reserved:world:incoming:80',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: 'reserved:world:incoming',
    destinationPort: 80,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '1',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: '91085aa98c983e249442e887d70ebc568f4ef07b',
    destinationPort: 9981,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '2',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: 'ca111583bf9c9a3547c0c10aa5e77aa97d0e6a14',
    destinationPort: 9200,
    ipProtocol: IPProtocol.UDP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '3',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: '5eee91c15081c2d4fac733c517ecb71ac095053a',
    destinationPort: 9092,
    ipProtocol: IPProtocol.ICMPv4,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '4',
    sourceId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationId: '3ccf93bf4b7a7e6b832bcfac1df78ddf26450a9e',
    destinationPort: 9982,
    ipProtocol: IPProtocol.ICMPv6,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '5',
    sourceId: '91085aa98c983e249442e887d70ebc568f4ef07b',
    destinationId: 'ca111583bf9c9a3547c0c10aa5e77aa97d0e6a14',
    destinationPort: 9201,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '6',
    sourceId: '872ihs09123iou897ykjashk291029oospi09123',
    destinationId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationPort: 9201,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
  {
    id: '7',
    sourceId: 'k8sbkjh1279asjk980712375hsakfs98109822nj',
    destinationId: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    destinationPort: 9201,
    ipProtocol: IPProtocol.TCP,
    verdict: Verdict.Forwarded,
    authType: AuthType.Disbaled,
    isEncrypted: false,
  },
];

export const services: HubbleService[] = [
  {
    id: 'reserved:world:outgoing',
    name: 'World',
    namespace: selectedNamespace,
    labels: [{ key: 'reserved:world', value: '' }],
    dnsNames: [],
    egressPolicyEnforced: false,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'reserved:world:incoming',
    name: 'google.com',
    namespace: selectedNamespace,
    labels: [{ key: 'reserved:world', value: '' }],
    dnsNames: ['google.com'],
    egressPolicyEnforced: false,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'a8de92d55119c9a6bb6a6dd66bcf012fabefb32d',
    name: 'coreapi',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'coreapi' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: false,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: '91085aa98c983e249442e887d70ebc568f4ef07b',
    name: 'crawler',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'crawler' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'ca111583bf9c9a3547c0c10aa5e77aa97d0e6a14',
    name: 'elasticsearch',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'elasticsearch' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: false,
    ingressPolicyEnforced: true,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'd2f41b3210a1447bae8f194e1f611793e381a4f0',
    name: 'jobposting',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app.kubernetes.io/name', value: 'ydz' },
      { key: 'k8s:app', value: 'jobposting' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: true,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: '5eee91c15081c2d4fac733c517ecb71ac095053a',
    name: 'kafka',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'kafka' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
      { key: 'k8s:statefulset.kubernetes.io/pod-name', value: 'kafka-0' },
    ],
    dnsNames: [],
    egressPolicyEnforced: false,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'a31f0187f650569a41eb9ddd48ca470c96c7d753',
    name: 'loader',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'loader' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: true,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: '3ccf93bf4b7a7e6b832bcfac1df78ddf26450a9e',
    name: 'recruiter',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'recruiter' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: false,
    ingressPolicyEnforced: true,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: '669333a2d60b773d01e9a73837cd92fac3d9ecf0',
    name: 'zookeeper',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'zookeeper' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: '872ihs09123iou897ykjashk291029oospi09123',
    name: 'remote-node-bug',
    namespace: selectedNamespace,
    labels: [{ key: 'reserved:remote-node', value: '' }],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'k8sbkjh1279asjk980712375hsakfs98109822nj',
    name: 'kube-dns',
    namespace: 'kube-system',
    labels: [
      { key: 'k8s:k8s-app', value: 'kube-dns' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: 'kube-system' },
    ],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
  {
    id: 'prometheus-app',
    name: 'prometheus',
    namespace: selectedNamespace,
    labels: [
      { key: 'k8s:app', value: 'prometheus' },
      { key: 'k8s:io.cilium.k8s.policy.cluster', value: 'default' },
      { key: 'k8s:io.cilium.k8s.policy.serviceaccount', value: 'default' },
      { key: 'k8s:io.kubernetes.pod.namespace', value: selectedNamespace },
    ],
    dnsNames: [],
    egressPolicyEnforced: true,
    ingressPolicyEnforced: false,
    visibilityPolicyStatus: '?unknown?',
    creationTimestamp: dataHelpers.msToPbTimestamp(Date.now()),
  },
];

export const flows: HubbleFlow[] = range(1000).map((): HubbleFlow => {
  return {
    source: {
      id: 0,
      identity: 0,
      labelsList: ['app=kafka'],
      namespace: 'kube-system',
      podName: `kafka-${Math.random() * 10}`,
    },
    time: {
      seconds: Date.now() + Math.random() * 1000,
      nanos: Date.now() + Math.random() * 1000,
    },
    destination: {
      id: 1,
      identity: 1,
      labelsList: ['app=loader'],
      namespace: 'kube-system',
      podName: `loader-${Math.random() * 10}`,
    },
    destinationNamesList: [],
    dropReason: 0,
    nodeName: '',
    reply: false,
    sourceNamesList: [],
    summary: '',
    type: FlowType.L34,
    l4: {
      tcp: {
        destinationPort: Math.random() <= 0.5 ? 80 : 443,
        sourcePort: Math.random() * 5000,
      },
    },
    verdict: Math.random() <= 0.5 ? Verdict.Forwarded : Verdict.Dropped,
    trafficDirection:
      Math.random() <= 0.5 ? TrafficDirection.Egress : TrafficDirection.Ingress,
    authType: AuthType.Disbaled,
  };
});
